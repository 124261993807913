
.portal-3d{
    /* background-color: rgba(29, 27, 27, 0); */
    border-radius: 5px;
    /* max-width: 640px;
    max-height: 120px; */
    /* min-width: 250px;*/
    height: 500px; 
    width: 700px;
    box-shadow: 0 0px 12px rgba(0, 254, 246, 0.7);
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 40pt;
    /* transition: box-shadow 1.3 ease-in-out forwards; */
}
.portal-3d:hover{
    transform: scale(1.05, 1.05);
    box-shadow: 0 0px 52px rgba(0, 254, 246, 0.7);
    cursor: pointer;
}
.portal-1{
    background: url('../../public/lib.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.portal-2{
    background: url('../../public/fac.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


.portal-3d::before {
    content: "";
    position: absolute;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
    /* top: 0;
    left: 0;
    width: 100%;
    height: 100%; */
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
}


.portal-3d:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 1;
}

.portal-3d p{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    align-self: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
}
.portal-3d:hover p{
    /* color: aqua; */
    opacity: 1;
    z-index: 3;
}