/* ! Borders ---------------------------------------------------------------------------------------------- */

/** Globals -------------------------------------------------*/

/* Styles --------------------- */

.border-dashed{
    border-style: dashed;
}
.border-dotted{
    border-style: dotted;
}
.border-double{
    border-style: double;
}
.border-groove{
    border-style: groove;
}
.border-inset{
    border-style: inset;
}
.border-outset{
    border-style: outset;
}
.border-ridge{
    border-style: ridge;
}
.border-solid{
    border-style: solid;
}

/* Widths --------------------- */

.border-w1{
    border-width: 1px;
}
.border-w1{
    border-width: 2px;
}
.border-w2{
    border-width: 4px;
}
.border-w3{
    border-width: 6px;
}
.border-w4{
    border-width: 8px;
}
.border-w5{
    border-width: 10px;
}

/* Colors --------------------- */
.border-black{
    border-color: #131313;
}  
.border-white{
    border-color: #e5dddd;
}
.border-red{
    border-color: #912626;
}
.border-green{
    border-color: #0fda75;
}  
.border-blue{
    border-color: #1768eb;
}  
.border-bright{
    border-color: #b1cdfa;
} 
.border-dark{
    border-color: #171b22;
}  
.border-gold{
    border-color: #fff201;
}  

/* Radii --------------------- */
.border-r1{
    border-radius: 5px;
}
.border-r2{
    border-radius: 10px;
}
.border-r3{
    border-radius: 15px;
}
.border-r4{
    border-radius: 20px;
}
.border-r5{
    border-radius: 25px;
}

/** Top -------------------------------------------------*/

/* Styles --------------------- */

.border-top-dashed{
    border-top-style: dashed;
}
.border-top-dotted{
    border-top-style: dotted;
}
.border-top-double{
    border-top-style: double;
}
.border-top-groove{
    border-top-style: groove;
}
.border-top-inset{
    border-top-style: inset;
}
.border-top-outset{
    border-top-style: outset;
}
.border-top-ridge{
    border-top-style: ridge;
}
.border-top-solid{
    border-top-style: solid;
}

/* Widths --------------------- */
.border-top-w0{
    border-top-width: 1.5px;
}
.border-top-w1{
    border-top-width: 3px;
}
.border-top-w2{
    border-top-width: 5px;
}
.border-top-w3{
    border-top-width: 7px;
}
.border-top-w4{
    border-top-width: 10px;
}
.border-top-w5{
    border-top-width: 12px;
}
.border-top-w6{
    border-top-width: 15px;
}

/* Colors --------------------- */
.border-top-black{
    border-top-color: #131313;
}  
.border-top-white{
    border-top-color: #e5dddd;
}
.border-top-red{
    border-top-color: #912626;
}
.border-top-green{
    border-top-color: #0fda75;
}  
.border-top-blue{
    border-top-color: #1768eb;
}  
.border-top-bright{
    border-top-color: #b1cdfa;
} 
.border-top-dark{
    border-top-color: #171b22;
}  

/** Bottom -------------------------------------------------*/

/* Styles --------------------- */

.border-bottom-dashed{
    border-bottom-style: dashed;
}
.border-bottom-dotted{
    border-bottom-style: dotted;
}
.border-bottom-double{
    border-bottom-style: double;
}
.border-bottom-groove{
    border-bottom-style: groove;
}
.border-bottom-inset{
    border-bottom-style: inset;
}
.border-bottom-outset{
    border-bottom-style: outset;
}
.border-bottom-ridge{
    border-bottom-style: ridge;
}
.border-bottom-solid{
    border-bottom-style: solid;
}

/* Widths --------------------- */

.border-bottom-w0{
    border-bottom-width: 1.5px;
}
.border-bottom-w1{
    border-bottom-width: 3px;
}
.border-bottom-w2{
    border-bottom-width: 5px;
}
.border-bottom-w3{
    border-bottom-width: 7px;
}
.border-bottom-w4{
    border-bottom-width: 10px;
}
.border-bottom-w5{
    border-bottom-width: 12px;
}
.border-bottom-w6{
    border-bottom-width: 15px;
}

/* Colors --------------------- */
.border-bottom-black{
    border-bottom-color: #131313;
}  
.border-bottom-white{
    border-bottom-color: #e5dddd;
}
.border-bottom-red{
    border-bottom-color: #912626;
}
.border-bottom-green{
    border-bottom-color: #0fda75;
}  
.border-bottom-blue{
    border-bottom-color: #1768eb;
} 
.border-bottom-bright{
    border-bottom-color: #b1cdfa;
} 
.border-bottom-dark{
    border-bottom-color: #171b22;
}   


/** Left -------------------------------------------------*/

/* Styles --------------------- */

.border-left-dashed{
    border-left-style: dashed;
}
.border-left-dotted{
    border-left-style: dotted;
}
.border-left-double{
    border-left-style: double;
}
.border-left-groove{
    border-left-style: groove;
}
.border-left-inset{
    border-left-style: inset;
}
.border-left-outset{
    border-left-style: outset;
}
.border-left-ridge{
    border-left-style: ridge;
}
.border-left-solid{
    border-left-style: solid;
}

/* Widths --------------------- */

.border-left-w0{
    border-left-width: 1.5px;
}
.border-left-w1{
    border-left-width: 3px;
}
.border-left-w2{
    border-left-width: 5px;
}
.border-left-w3{
    border-left-width: 7px;
}
.border-left-w4{
    border-left-width: 10px;
}
.border-left-w5{
    border-left-width: 12px;
}
.border-left-w6{
    border-left-width: 15px;
}

/* Colors --------------------- */
.border-left-black{
    border-left-color: #131313;
}  
.border-left-white{
    border-left-color: #e5dddd;
}
.border-left-red{
    border-left-color: #912626;
}
.border-left-green{
    border-left-color: #0fda75;
}  
.border-left-blue{
    border-left-color: #1768eb;
}
.border-left-bright{
    border-left-color: #b1cdfa;
} 
.border-left-dark{
    border-left-color: #171b22;
}  


/** Right -------------------------------------------------*/

/* Styles --------------------- */

.border-right-dashed{
    border-right-style: dashed;
}
.border-right-dotted{
    border-right-style: dotted;
}
.border-right-double{
    border-right-style: double;
}
.border-right-groove{
    border-right-style: groove;
}
.border-right-inset{
    border-right-style: inset;
}
.border-right-outset{
    border-right-style: outset;
}
.border-right-ridge{
    border-right-style: ridge;
}
.border-right-solid{
    border-right-style: solid;
}

/* Widths --------------------- */

.border-right-w0{
    border-right-width: 1.5px;
}
.border-right-w1{
    border-right-width: 3px;
}
.border-right-w2{
    border-right-width: 5px;
}
.border-right-w3{
    border-right-width: 7px;
}
.border-right-w4{
    border-right-width: 10px;
}
.border-right-w5{
    border-right-width: 12px;
}
.border-right-w6{
    border-right-width: 15px;
}

/* Colors --------------------- */
.border-right-black{
    border-right-color: #131313;
}  
.border-right-white{
    border-right-color: #e5dddd;
}
.border-right-red{
    border-right-color: #912626;
}
.border-right-green{
    border-right-color: #0fda75;
}  
.border-right-blue{
    border-right-color: #1768eb;
} 
.border-right-bright{
    border-right-color: #b1cdfa;
} 
.border-right-dark{
    border-right-color: #171b22;
}   