@import './fonts.css';
@import './borders.css';
@import './justification.css';
@import './scrollbar.css';
@import './portals3d.css';
@import './skill-nav.css';
@import './music-player.css';
@import './overlays.css';

html{
    /* scroll-behavior: smooth; */
}

body {
    /* background-color: rgba(14, 13, 13, 0); */
    color: rgb(246, 246, 241);
    margin: 0;
    padding: 0;
    background-color: rgb(0, 0, 0);
    /* overflow: hidden; */
    /* background: url('../../public/b3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; */
}

a:link { text-decoration: none; color: rgb(0, 255, 255) }


a:visited { text-decoration: none; color: aqua }


a:hover { text-decoration: none; color: rgb(187, 255, 255) }


a:active { text-decoration: none; color: rgb(15, 111, 111) }


#contact{
    margin-top: 50vh;
    margin-bottom: 90vh;
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.nav{
    background-color: rgb(29, 27, 27);
    height: 50px;
    width: 100%;
    position: fixed;
    /* margin-top: 5%; */
    margin-top: 0;
    display: flex;
    z-index: 2;
}

.navigation{
    list-style: none;
    display: flex;
    /* flex: auto; */
}

.navigation li{
    margin-left: 20px;
    align-self: center;
}

.navigation-small{
    margin-top: 50px;
    width: 100%;
    position: fixed;
    z-index: 200;
    /* list-style: none; */
    display: flex;
    flex-direction: column;
    /* width: 94vw; */
    /* margin-right: 20px; */
    /* padding: 50px; */
    padding-bottom: 20px;
    background-color: #111212;
    /* padding-left: 20px; */
    /* padding-top: 3%; */
    /* padding-right: 20px; */
    border-bottom: solid 2px white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /* position: relative; */
    /* height: 300px; */
    /* width: 100vw; */
    /* justify-content: center; */
}

.wide-a{
    /* margin: 25px; */
    /* align-self: center; */
    /* position: absolute; */
    /* max-width: 100vw;
    min-width: 100vw; */
    cursor: pointer;
    margin: 0.1%;
    width: 100%;
    text-align: center;
    text-decoration: none;
    text-decoration-line: none ;
    background-color: #363434;
    /* padding: 5px; */
    border-radius: 1px;
    transition: all 0.2s ease-in-out;
}

.nav-link{
    text-decoration: none;
    text-decoration-line: none ;
    background-color: rgb(29, 27, 27);
    padding: 5px;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    /* height: 100%; */
}
.nav-link:hover{
    background-color: rgb(12, 12, 12);
}

.visible{
    /* color: #000000; */
    background-color: #fdc700;
}

.logo-img{
    align-self: center;
    margin-left: 1%;
}



.small-nav{
    display: flex;
    margin-left: auto;
    /* justify-self: left; */
}
.small-nav-img{
    align-self: center;
    cursor: pointer;
    height: 32px;
    width: 32px;
    transform: scale(1, 1);
    transition: color 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
}
.small-nav-img:hover{
    color: aqua;
    transform: scale(1.04, 1.04);
}

/*------------------------------------------------------------------------------------------------------------------------------------------*/

.main-title {
    /* background-image: url('../../public/b7.jpg'); */
    background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    color: #000000;
    /* For WebKit/Blink */
    -webkit-text-stroke: 1pt rgb(0, 251, 255);

    /* For Firefox */
    text-stroke: 1px rgb(0, 253, 236);
    -webkit-text-stroke-width: 1px;
    /* -webkit-text-fill-color: transparent; */
}
.tech {
    
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    /* For WebKit/Blink */
    -webkit-text-stroke: 0.1px rgb(126, 255, 253);

    /* For Firefox */
    text-stroke: 0.1px rgb(126, 255, 253);
    -webkit-text-stroke-width: 1px;
    -webkit-text-fill-color: transparent;
}
.tech-art {
    
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: rgb(0, 0, 0);
    /* For WebKit/Blink */
    -webkit-text-stroke: 2.3px rgb(126, 255, 253);

    /* For Firefox */
    text-stroke: 2.3px rgb(126, 255, 253);
    -webkit-text-stroke-width: 1.1px;
    -webkit-text-fill-color: rgb(0, 0, 0);
}



.land-button{
    border-radius: 12px;
    font-size: 32pt;
    /* padding-top: 15px; */
    color: rgb(255, 174, 0);
    background-color: rgba(100, 148, 237, 0.116);
}
.va-mid{
    padding-top: 30%;
    vertical-align: middle;
    background: transparent;
}

.ppe{
    /* width: 2px;
    height: 2px; */
    padding-left: 5px;
    height: 1px;
}

.nav-area{
    /* background-color: rgba(240, 248, 255, 0.93); */
    /* max-width: 75vw; */
}

.swa{
    background-color: rgba(240, 248, 255, 1);
    /* z-index: -3; */
}

.about{
    background-image: linear-gradient(to bottom,#ffffff, #f0f8ff, #a9aeb2, #858585 ) ;
    width: 75vw;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* transition: all 0.3s ease-in-out; */
    /* background: url('../../public/g.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center; */
   
}

.hero-img{
    width: 90%;
    /* min-width: 50%; */
    max-width: 640px;
    margin: 5%;
    box-shadow: 0 0px 12px rgba(0, 254, 246, 0.7);
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in-out;
}
.hero-img:hover{
    transform: scale(1.05, 1.05);
    box-shadow: 0 0px 52px rgba(0, 254, 246, 0.7);
}


.left{
    left: 0;
    justify-self: left;
}

.area-text{
    color:white;
    background-color: rgba(0, 0, 0, 0.814);
    border-radius: 5px;
}


.area-text-n{
    /* color:white; */
    background-color: rgba(0, 0, 0, 0.814);
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
}
.area-text-n:hover{
    background-color: rgba(0, 0, 0, 0.414);
}

.area-text-skills{
    color:rgb(0, 247, 255);
    background-color: rgba(0, 0, 0, 0.534);
    margin-bottom: -40px;
    cursor: default;
}
.area-text-skills span{
    color: rgb(0, 255, 255);
    cursor: default;
    transform: scale(1, 1);
    transition: all 0.2s ease-in-out;
}
.area-text-skills span:hover{
    color: rgb(161, 255, 255);
    transform: scale(1.1, 1.1);
    transition: all 0.2s ease-in-out;
}

.tour-img-container{
  display: flex;  
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  /* width: 100%; */
}

.entry-article{
    /* background-color: rgba(29, 27, 27, 0); */
    border-radius: 5px;
    width: 350px;
    height: 250px;
    /* min-width: 350px;
    min-height: 250px; */
    box-shadow: 0 0px 12px rgba(0, 254, 246, 0.7);
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    /* transition: box-shadow 1.3 ease-in-out forwards; */
}
.entry-article:hover{
    transform: scale(1.05, 1.05);
    box-shadow: 0 0px 52px rgba(0, 254, 246, 0.7);
}
.article-strategize{
    background: url('../../public/strategize_logo2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.article-regime-change{
    background: url('../../public/RegimeChangeBanner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.entry-article::before {
    content: "";
    position: absolute;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
    /* top: 0;
    left: 0;
    width: 100%;
    height: 100%; */
    background-color: rgba(0, 0, 0, 0);
    z-index: 1;
}


.entry-article:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 1;
}

.entry-article p{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    align-self: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
}
.entry-article:hover p{
    color: aqua;
    opacity: 1;
    z-index: 3;
}
/* .entry-article-1::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
}
.entry-article-1:hover::after{
    opacity: 1;
} */




.entry-article-2{
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 5px;
    max-width: 350px;
    max-height: 250px;
    min-width: 350px;
    min-height: 250px;

}

.entry-title{
    border-bottom: solid 2px white;
    color: rgb(255, 191, 0);
    width: fit-content;
    /* margin-left: 10%; */
}

.sub-title{
    font-size: 6vw;
    color:azure;
}

.b-img-0{
    background: url('../../public/code_img_test.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(81, 79, 79);
    
    /* */
}

.cg-back{
    background: url('../../public/3d-back.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.b-img-title{
    background: url('../../public/title-back.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    min-height: 150vh;
    /* background-color: rgba(240, 248, 255, 0); */
    /* background-image: linear-gradient(to bottom, #5151517d, #5352526f, #4141413d, #1c1c1c32, #07070700); */
}

.b-img-1{
    /* background: url('../../public/chamferPiano.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; */
    background-color: rgba(240, 248, 255, 0);
}

.b-img-2{
    background: url('../../public/CelloRender.jpg');
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center center;
}
.b-img-cello{
    background: url('../../public/CelloRender-small.jpg');
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center center;
}

.skill-icon{
    height: 128px;
    width: 128px;
    margin: 5%;
}
.skill-icon:hover{
    color: aqua;
}
.link-icon{
    color: aliceblue;
    margin: 1%;
    width: 64px;
    height: 64px;
    transition: all 0.3s ease-in-out;
}
.link-icon:hover{
    color: aqua;
    cursor:pointer;
    transform: scale(1.05, 1.05);
}
.link-icon-dark{
    color: rgb(19, 20, 21);
    /* margin: 1%; */
    justify-self: center;
    width: 64px;
    height: 64px;
    transition: all 0.3s ease-in-out;
}
.link-icon-dark:hover{
    color: rgb(255, 221, 0);
    cursor:pointer;
    transform: scale(1.05, 1.05);
}
.contact-box{
    border-top: 2px groove black;
    width: 200px;
    height: 200px;
    font-size: 8pt;
    margin-bottom: 100px;
    transition: background-color 0.3s ease-in-out;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.contact-box:hover{
    background-color: rgb(140, 142, 142);
}

.down-arrow{
    color: aliceblue;
    margin: 1%;
    width: 64px;
    height: 64px;
    box-shadow: 0 0px 12px rgba(0, 254, 246, 0);
    border: 2px solid rgba(255, 255, 255, 0);
    transition: all 0.4s ease-in-out;
    animation: arrow-anim 1.5s ease-in-out infinite;
    border-radius: 50%;
}
.down-arrow:hover{
    color: aqua;
    cursor:pointer;
    border: 2px solid white;
    box-shadow: 0 0px 42px rgba(0, 254, 246, 0.7);
}

.sb-btn{
    border: 2px solid aqua;
    border-radius:5px; 
    box-shadow: 0 0px 12px rgba(0, 255, 255, 0.4);
    align-self: flex-end;
    max-width: 200px;
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;
}
.sb-btn:hover{
    box-shadow: 0 0px 18px rgba(0, 255, 255, 0.5);
}

.sb-field{
    border: 1px solid aqua;
    border-radius:5px; 
    box-shadow: 0 0px 2px rgba(0, 255, 255, 0.4);
    align-self: flex-end;
    max-width: 200px;
    cursor: default;
    transition: box-shadow 0.2s ease-in-out;
    margin: 2px;
    padding: 1px;
}
.sb-field:hover{
    box-shadow: 0 0px 4px rgba(0, 255, 255, 0.5);
}

@keyframes arrow-anim {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(15px);
    }
    100%{
        transform: translateY(0);
    }
}


@media (min-width: 1801px) {
    .skill-icon{
        height: 96px;
        width: 96px;
    }
    .skill-img-container{

    }
    .skill-img{
        /* height: 128px; */
        width: 1000px;
    }
}

@media (max-width: 1800px) {
    .skill-icon{
        height: 90px;
        width: 90px;
    }
    
}

@media (max-width: 1400px) {
    .skill-icon{
        height: 88px;
        width: 88px;
    }
    
}

@media (max-width: 1050px) {
    .skill-img{
        /* height: 128px; */
        width: 700px;
    }
}

@media (max-width: 1000px) {
    .skill-icon{
        height: 86px;
        width: 86px;
    }
    
    .portal-3d{
        height: 420px;
        width: 600px;
    }
}

@media (max-width: 830px) {
    .portal-3d{
        height: 400px;
        width: 550px;
    }
    
}

@media (max-width: 730px) {
    .skill-img{
        width: 600px;
    }
    .portal-3d{
        height: 380px;
        width: 525px;
    }
    .navigation li{
        margin-left: 15px;
    }
}

@media (max-width: 700px) {
    .skill-icon{
        height: 64px;
        width: 64px;
    }
    .main-title{
        font-size: 90pt;
    }
}

@media (max-width: 670px) {
    .portal-3d{
        height: 360px;
        width: 500px;
    }
    .navigation li{
        margin-left: 10px;
    }
}

@media (max-width: 640px) {
    .nav-button{
        width: 75vw;
    }
    .nav-area{
        max-width:unset;
    }
    .btn-left{
        border-top-right-radius: 10px;
    }
    .btn-right{
        border-top-right-radius: 0px;
    }

    .skill-img{
        width: 500px;
    }

    .portal-3d{
        height: 340px;
        width: 480px;
    }
}

@media (max-width: 600px) {
    .portal-3d{
        height: 320px;
        width: 460px;
    }
    .navigation li{
        margin-left: 5px;
    }
}

@media (max-width: 550px) {
    .skill-icon{
        height: 52px;
        width: 52px;
    }
    .skill-img{
        width: 450px;
    }
    .portal-3d{
        height: 295px;
        width: 430px;
    }
    .main-title{
        font-size: 80pt;
    }
    .navigation li{
        margin-left: 3px;
    }
}

@media (max-width: 520px) {
    .skill-img{
        width: 450px;
    }
    .portal-3d{
        height: 275px;
        width: 410px;
    }
    .navigation li{
        margin-left: 1px;
    }
}

@media (max-width: 488px) {
    .skill-img {
        width: 425px;
    }
    .portal-3d{
        height: 450px;
        width: 380px;
        font-size: 35pt;
    }
}
@media (max-width: 460px) {
    .skill-img{
        width: 400px;
    }
    .portal-3d{
        height: 420px;
        width: 350px;
    }
}
@media (max-width: 430px) {
    .skill-img{
        width: 380px;
    }
    .entry-article{
        height: 230px;
        width: 320px;
    }
    .portal-3d{
        height: 390px;
        width: 320px;
        font-size: 30pt;
    }
    .main-title{
        font-size: 70pt;
    }
}
@media (max-width: 400px) {
    .skill-icon{
        height: 46px;
        width: 46px;
    }
    .skill-img{
        width: 350px;
    }
    .entry-article{
        height: 200px;
        width: 290px;
    }
    .portal-3d{
        height: 375px;
        width: 300px;
        font-size: 25pt;
    }
}
@media (max-width: 375px) {
    .skill-img{
        width: 345px;
    }
    .entry-article{
        height: 180px;
        width: 270px;
    }
    .portal-3d{
        height: 360px;
        width: 280px;
        font-size: 23pt;
    }
    .link-icon{
        height: 48px;
        width: 48px;
    }
}

@media (max-width: 365px) {
    .skill-img{
        width: 330px;
    }
    .entry-article{
        height: 170px;
        width: 260px;
    }
    .portal-3d{
        height: 350px;
        width: 260px;
    }
    .main-title{
        font-size: 65pt;
    }
}

@media (max-width: 350px) {
    .skill-img{
        width: 310px;
    }
    .entry-article{
        height: 155px;
        width: 240px;
    }
    .portal-3d{
        height: 355px;
        width: 240px;
        font-size: 21pt;
    }
    .link-icon{
        height: 40px;
        width: 40px;
    }
}
@media (max-width: 335px) {
    .skill-img{
        width: 290px;
    }
    .entry-article{
        height: 135px;
        width: 220px;
    }
    .portal-3d{
        height: 335px;
        width: 220px;
    }
    .link-icon{
        height: 32px;
        width: 32px;
    }
}

/* TODO ----------------------------------------------------------------------------------------- */

/* .canvas-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}
  
.canvas-container canvas {
width: 100%;
height: 100%;
} */
  
.canvas-container {
    position: relative;
    width: 100%;
    height: 100%;
}
  
.music-player{
    background-color: rgb(64, 60, 60);
    border-radius: 12px;
    /* width:min-content; */
}
.music-player-button{
    padding: 3px 3px;
    border-radius: 10px;
    border: solid 3px transparent;
    margin: 2px;
    /* color: rgb(38, 42, 42); */
    vertical-align: middle;
    align-items: center;
    align-self: center;
    background-color: rgb(255, 255, 255);
}
.music-player-button:hover{
    border: solid 3px aqua;
}
.song-title{
    align-self: center;
    margin: 20px;
}
.vid-frame{
    height: 20vw;
    width: 30vw;
    padding: 25px;
    min-width: 240px;
    min-height: 200px;
}

.lnk{
    background-color: rgb(177, 228, 255);
    color: rgb(34, 0, 255);
    text-decoration: none;
    text-decoration-color: blue;
    border: solid 3px transparent;
    border-radius: 5px;
    padding: 2px;
    font-size: 16pt;
}

.lnk:hover{
    border: solid 3px rgb(255, 204, 0);
    background: linear-gradient(to top, #ffd000, #004cff );
}