.arch-canvas-overlay{
    display: flex;
    /* justify-items: center; */
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.883);
    
    opacity: 1;
    min-width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    /* max-width: 100%;
    max-height: 100%; */
    transform: scale(1, 1);
    position:fixed;
    z-index: 3;
    align-items: center;
    /* align-self: top; */
    /* vertical-align: top; */
    box-shadow: 200px 200px -112px rgba(0, 254, 246, 0.7);
    transition: opacity 0.5s ease-in-out;
}
.stream-off{
    box-shadow: 200px 200px -132px rgba(0, 254, 246, 0.7);
    opacity: 0;
    z-index: -2;
    /* transform: scale(0.2, 0.2); */
    /* transition: all 0.5s ease-in-out; */
}

.project-details{
    display: flex;
    /* justify-items: center; */
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.883);
    
    opacity: 1;
    min-width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    /* max-width: 100%;
    max-height: 100%; */
    transform: scale(1, 1);
    position:fixed;
    z-index: 3;
    align-items: center;
    /* align-self: top; */
    /* vertical-align: top; */
    box-shadow: 200px 200px -112px rgba(0, 254, 246, 0.7);
    transition: opacity 0.5s ease-in-out;
}
.details-off{
    box-shadow: 200px 200px -132px rgba(0, 254, 246, 0.7);
    opacity: 0;
    z-index: -2;
    /* transform: scale(0.2, 0.2); */
    /* transition: all 0.5s ease-in-out; */
}

.overlay-on{
    /* display: flex; */
    /* justify-items: center; */
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.883);
    
    opacity: 1;
    /* min-width: 100vw; */
    min-height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    /* max-width: 100%;
    max-height: 100%; */
    transform: scale(1, 1);
    position:fixed;
    z-index: 1;
    align-items: center;
    /* align-self: top; */
    /* vertical-align: top; */
    /* box-shadow: 200px 200px -112px rgba(0, 254, 246, 0.7); */
    transition: opacity 0.5s ease-in-out;
}
.overlay-off{
    /* box-shadow: 200px 200px -132px rgba(0, 254, 246, 0.7); */
    opacity: 0;
    z-index: -2;
    /* transform: scale(0.2, 0.2); */
    /* transition: all 0.5s ease-in-out; */
}

.overlay-nav{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.overlay-nav-t{
    margin-top: 50px;
    display: flex;
    /* justify-self: center; */
    justify-content: space-between;
    /* position: fixed; */
    transform: translateY(-200px);
    z-index: 5;
}

.overlay-nav-btn{
    color:antiquewhite;
    border: 2px solid transparent;
    background-color: rgb(59, 58, 58);
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}
.overlay-nav-btn:hover{
    color:aqua;
    border: 2px solid white;
    background-color: rgb(46, 43, 43);
    /* border-radius: 25%; */
    cursor: pointer;
    box-shadow: 0 0px 52px rgba(0, 254, 246, 0.7);
}

.nav-btn-deactivated{
    color:rgb(37, 36, 35);
    border: 2px solid transparent;
    background-color: rgb(25, 24, 24);
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}
.nav-btn-deactivated:hover{
    color:rgb(22, 22, 22);
    background-color: rgb(12, 12, 12);
}

.nav-img{
    height: 100%;
    width: 100%;
}

.scroll-img{
    box-shadow: 0 0px 8px rgba(0, 254, 246, 0.7);
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    border: 2px solid transparent;
}
.scroll-img:hover{
    box-shadow: 0 0px 30px rgba(0, 254, 246, 0.7);
    border: 2px solid rgb(255, 187, 0);
}

/* .scroll-img-parent{
    position: relative;
    height: 10vh;
    width: 20vw;
}

.scroll-img-parent::before{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.597);
    opacity: 1;
    z-index: 15;
    display: inline-block;
    transition: all 0.2s ease-in-out;
}

.scroll-img{
    position: relative;
    height: 10vh;
    width: 20vw;
}

/* .scroll-img:hover::before{
    opacity: 1;
} */ 