/* ! Font Families -------------------------------------------------------------------------------------------------------------- */

.font-1 {
    font-family: 'Kanit', cursive;
    /*font-weight: 300;  Use the regular weight a*/
}
.font-2 {
    font-family: 'Abril Fatface', cursive; 
}

.font-3 {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif; 
}

.font-4 {
    font-family: 'Bitter', serif; 
}

.font-5 {
    font-family: 'IBM Plex Mono', monospace; 
}

.font-6 {
    font-family: 'Josefin Sans', sans-serif; 
}

.font-7 {
    font-family: 'Yanone Kaffeesatz', sans-serif;
}

.font-8 {
    font-family: 'Rajdhani', sans-serif; 
}

.font-9 {
    font-family: 'Righteous', cursive; 
}

.font-10 {
    font-family: 'Satisfy', cursive; 
}

.font-11 {
    font-family: 'Julee', cursive;
}

/* ! Font Sizes -------------------------------------------------------------------------------------------------------------- */

/*
* px: Pixels, a fixed-size unit.
* em: Relative to the font size of the parent element.
* rem: Relative to the font size of the root element (usually the html element).
* %: Relative to the font size of the parent element.
* vw: Relative to 1% of the width of the viewport.
* vh: Relative to 1% of the height of the viewport.     ?!? dvh, lvh, svh ?!?
*/

.s0{
    font-size: 8pt;
}
.s1{
    font-size: 12pt;
}
.s2{
    font-size: 14pt;
}
.s3{
    font-size: 28pt;
}
.s4{
    font-size: 40pt;
}
.s-x{
    font-size: 48pt;
}
.s-xx{
    font-size: 56pt;
}
.s-xxx{
    font-size: 64pt;
}
.s-vw1{
    font-size: 130pt;
}
.s5{
    font-size: 14vw;
}
.s6{
    font-size: 18vw;
}
.s7{
    font-size: 22vw;
}


/* .pt1{
    font-size: 42pt;
}
.pt2{
    font-size: 44pt;
}
.pt3{
    font-size: 46pt;
} */

/* ! Font Colors -------------------------------------------------------------------------------------------------------------- */

.white{
    color:antiquewhite;
}

.black{
    color: rgb(14, 14, 14);
}

.red{
    color:hsl(0, 90%, 37%);
}

.orange{
    color:hsl(41, 98%, 49%);
}

.blue{
    color: rgb(44, 31, 222);
}

.teal{
    color: rgb(20, 243, 255);
}
.gold{
    color: #fdc700;
}
