
.component {
  background-color: rgb(255, 255, 255);
  color: #27ae60;
  width: 25%;
  max-width: 600px;
  margin: 1em auto;
  padding-bottom: 2em;
  border: 0.1px solid black;
  border-radius: 10px;
}

.musicCover {
  border-radius: 10%;
}

.playButton {
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
}

.subTitle {
  margin-top: -1em;
  color: #000000;
}

.time {
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  color: #828282;
  font-size: smaller;
}

.timeline {
  width: 80%;
  background-color: #27ae60;
}

input[type="range"] {
  background-color: #27ae60;
}

.play-status-off{
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.play-status-on{
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 900px) {
  .component {
    width: 50%;
  }
}