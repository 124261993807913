
/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 123, 123);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(1, 253, 253);
}

/* ----------------------------------------------------------- */


.scroll-container {
    width: 80vw;
    /* display: flex; */
    /* gap: 20px; */
    padding: 2%;
    /* padding-top: 2%;
    padding-bottom: 2%; */
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
  }
  
  .scroll-item {
    display: inline-block;
    width: 25vw;
    background-color: rgb(0, 0, 0);
    margin-right: 10px;
  }